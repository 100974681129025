var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mega-card",
    {
      staticClass: "card-border card-round rounded",
      class: { "overlay-10 overlay-primary active": _vm.isSelected }
    },
    [
      _c(
        "div",
        { staticClass: "card-header", staticStyle: { background: "none" } },
        [
          _c(
            "nav",
            { staticClass: "nav ml-auto" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-sm p-0",
                  attrs: { target: "_blank", to: "/round/" + _vm.id }
                },
                [_c("i", { staticClass: "i-external-link" })]
              ),
              _vm._v(" "),
              _vm.selectable
                ? _c(
                    "span",
                    {
                      staticClass: "btn btn-sm p-0",
                      attrs: { "data-click": "ignore" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("select-force", _vm.id)
                        }
                      }
                    },
                    [
                      _c("i", {
                        class: _vm.isSelected ? "i-check-square" : "i-square"
                      })
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          attrs: { "data-click": "ignore" },
          on: { dblclick: _vm.open, click: _vm.onClick }
        },
        [
          _c("div", { staticClass: "navbar py-3" }, [
            _c("div", { staticClass: "container-fluid" }, [
              _c(
                "div",
                { staticClass: "navbar-label" },
                [
                  _c("mega-image", {
                    staticClass: "rounded-full navbar-avatar mr-3",
                    attrs: { small: "", src: _vm.author.avatar }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _c("small", { staticClass: "strong" }, [
                      _vm._v(_vm._s(_vm.author.name))
                    ]),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("@" + _vm._s(_vm.author.username))
                    ])
                  ])
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "media" }, [
            _vm.video_link
              ? _c(
                  "div",
                  { staticClass: "card-media card-video" },
                  [
                    _c("mega-image", {
                      attrs: { ratio: "3x4", src: _vm.preview }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "media-overlay overlay-50 overlay-dark" },
                      [
                        _c("div", { staticClass: "text-center" }, [
                          _c(
                            "span",
                            { staticClass: "sticker sticker-lg bg-white" },
                            [_c("i", { staticClass: "i-play" })]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "media-overlay overlay-90 overlay-dark media-video",
                        on: { mouseenter: _vm.play, mouseleave: _vm.pause }
                      },
                      [
                        _c("video", {
                          ref: "video",
                          staticClass: "video-player fill-parent",
                          attrs: { src: _vm.video_link, loop: "" }
                        })
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.video_link
              ? _c(
                  "div",
                  { staticClass: "card-media" },
                  [
                    _c("mega-image", {
                      attrs: { ratio: "3x4", src: _vm.preview }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("nav", { staticClass: "nav font-small events-disable" }, [
            _c("span", { staticClass: "btn" }, [
              _c("i", { staticClass: "i-thumbs-up" }),
              _vm._v(" " + _vm._s(_vm.likes))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "btn" }, [
              _c("i", { staticClass: "i-thumbs-down" }),
              _vm._v(" " + _vm._s(_vm.dislikes))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "btn ml-auto" }, [
              _c("i", { staticClass: "i-message-circle" }),
              _vm._v(" " + _vm._s(_vm.comments))
            ])
          ]),
          _vm._v(" "),
          _c("nav", { staticClass: "nav flex-column font-small" }, [
            _c("div", { staticClass: "nav-text text-muted" }, [
              _c("i", { staticClass: "i-calendar" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm._f("u2d")(_vm.date, "MMMM DD YYYY - LT")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "nav-text text-muted" }, [
              _c("i", { staticClass: "i-map-pin" }),
              _vm._v(" "),
              _c("span", { class: { "text-danger": !_vm.address } }, [
                _vm._v(_vm._s(_vm.address ? _vm.address : "No address"))
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }